import data from '../data.json';

const SCORE = [5, 3, 1];

export const getScore = (index) => SCORE[index];

export const getRiskScore = (result) => {
  return Math.ceil((getFaultScore(result) + getCustomerScore(result)) / 2);
};

export const getFaultScore = (result) => {
  return Math.ceil(
    (getScore(result.occurenceTime) +
      getScore(result.faultServerity) +
      getScore(result.resolutionTime)) /
      3
  );
};

export const getCustomerScore = (result) => {
  return Math.max(getScore(result.impact), getScore(result.complaintLevel));
};
